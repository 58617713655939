<div class="orders-container">
  <div class="orders-header">
    <div class="row">
      <span class="title">Orders</span>
      <div class="button-group" *ngIf="(orders$ | async)?.shipmentOrderCount > 0 && !parentJobRefId">
        <button mat-raised-button color="primary" (click)="openCreateOrderDialog()">Create Orders</button>
        <button mat-raised-button color="primary">Import</button>
        <button mat-raised-button color="primary">Upload</button>
        <!--TODO: enable when backend is ready-->
        <button mat-raised-button color="warn" disabled="true">Reset</button>
      </div>
    </div>
    <div class="row" *ngIf="(orders$ | async)?.shipmentOrderCount > 0">
      <mat-form-field appearance="outline" class="dense-3">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" [formControl]="formControlSearch" />
      </mat-form-field>
    </div>
  </div>
  <div class="orders-body">
    <div class="empty-orders-card" *ngIf="!(orders$ | async)?.shipmentOrderCount">
      <div class="header">Import Orders</div>
      <div class="message">Import your orders using any of the following options</div>
      <div class="button-group">
        <button mat-raised-button color="primary" (click)="openCreateOrderDialog()">Create Orders</button>
        <button mat-raised-button color="primary">Import</button>
        <button mat-raised-button color="primary">Upload File</button>
      </div>
    </div>
    <div class="created-orders">
      <hmt-orders-container
        (editOrderAction)="editOrder($event)"
        (deleteOrderAction)="deleteOrder($event)"
        [orders]="orders$ | async"
        [injectedScreen]="injectedScreen$"
        [parentJobRefId]="parentJobRefId"></hmt-orders-container>
    </div>
    <mat-paginator
      *ngIf="(orders$ | async)?.shipmentOrderCount > 0"
      class="paginator"
      [length]="(orders$ | async)?.pageInfo?.total"
      [pageSize]="(orders$ | async)?.pageInfo?.pageSize"
      [pageSizeOptions]="PAGE_SIZE_OPTIONS"
      (page)="onPageChange($event)"
      aria-label="Select page">
    </mat-paginator>
  </div>
</div>
