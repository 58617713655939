<div class="shipment-reference-container">
  <div class="content">
    <div *ngFor="let shipment of shipments$ | async">
      <mat-expansion-panel class="panel" [expanded]="true">
        <mat-expansion-panel-header class="header">
          <mat-panel-title class="title">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (keypress)="$event.stopPropagation()"
              (change)="onShipmentSelectionChange(shipment, $event.checked)"></mat-checkbox>
            <span class="title-placeholder">Shipment Reference No : </span> &nbsp;{{ shipment?.shipmentRefNumber }}
          </mat-panel-title>
          <button mat-flat-button class="view-order-btn" (click)="viewOrderData(shipment?.orders)">View Order</button>
        </mat-expansion-panel-header>
        <div class="content services">
          <div *ngFor="let option of shipment?.serviceOptions; let i = index">
            <mat-expansion-panel class="panel" [expanded]="true">
              <mat-expansion-panel-header class="header">
                <mat-panel-title class="title">
                  <mat-checkbox
                    [checked]="selectedOption === option._id"
                    (change)="selectOption(shipment, option, $event.checked)"
                    (click)="$event.stopPropagation()"
                    (keypress)="$event.stopPropagation()">
                  </mat-checkbox>
                  <span class="title-placeholder">Option {{ i + 1 }} </span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content services">
                <mat-accordion multi>
                  <mat-expansion-panel *ngFor="let service of option?.services" [expanded]="false" class="no-shadow">
                    <mat-expansion-panel-header class="header">
                      <mat-panel-title class="title">
                        <mat-checkbox
                          [checked]="isServiceSelected(shipment, service)"
                          (change)="selectService(shipment, option, service, $event.checked)"
                          (click)="$event.stopPropagation()"
                          (keypress)="$event.stopPropagation()"
                          [disabled]="!isShipmentSelected">
                        </mat-checkbox>
                        <span> {{ service?.serviceName }} </span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</div>
