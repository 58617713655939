import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { filter, Observable, Subject, take, tap } from 'rxjs';
import { Parties } from '../../models/parties.model';
import { ServiceCard } from '../../models/service-card/service-card.model';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-service-card-job-summary',
  templateUrl: './service-card-job-summary.component.html',
  styleUrl: './service-card-job-summary.component.scss',
})
export class ServiceCardJobSummaryComponent extends FieldWrapper implements OnInit, OnDestroy {
  @Select(WizardState.getServiceCard) serviceCard$: Observable<ServiceCard>;
  private destroy$ = new Subject<void>();
  consigneeHeader: string;
  shipperHeader: string;
  parties: Parties;

  ngOnInit(): void {
    this.shipperHeader = 'Shipper Details';
    this.consigneeHeader = 'Consignee Details';
    this.serviceCard$
      .pipe(
        filter(serviceCard => !!serviceCard),
        take(1),
        tap(serviceCard => {
          this.parties = serviceCard.party;
          console.log(this.parties);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
