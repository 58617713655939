import { Component, inject } from '@angular/core';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { FieldWrapper } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { WorkflowState } from 'app/json-schema-forms/store/workflow.state';
import { take } from 'rxjs';
import { Order } from '../../models/order.model';
import { ServiceInfo } from '../../models/service-info.model';
import { ServiceOption } from '../../models/service-option.model';
import { Shipment } from '../../models/shipment.model';
import { DeselectService, DeselectShipment, SelectService, SelectShipment } from '../../store/wizard.actions';
import { WizardState } from '../../store/wizard.state';
import { ViewOrderCardsComponent } from '../view-order-cards/view-order-cards.component';

@Component({
  selector: 'app-shipment-reference-with-services',
  templateUrl: './shipment-reference-with-services.component.html',
  styleUrl: './shipment-reference-with-services.component.scss',
})
export class ShipmentReferenceWithServicesComponent extends FieldWrapper {
  injectableScreen = inject(Store).select(WorkflowState.getInjectedScreen);
  shipments$ = inject(Store).select(WizardState.getShipments);

  private readonly store$ = inject(Store);
  private readonly dialogHandlerService = inject(DialogHandlerService);

  isShipmentSelected: boolean;
  selectedOption: string | null = null;
  selectedShipmentId: string | null = null;

  onShipmentSelectionChange(shipment: Shipment, isSelected: boolean): void {
    if (isSelected) {
      this.store$.dispatch(new SelectShipment(shipment._id));
      this.isShipmentSelected = true;
      this.options.formState.validForTender = true;
    } else {
      shipment.serviceOptions.forEach(option => {
        option.services.forEach(service => {
          this.store$.dispatch(new DeselectService(shipment._id, service.plannedServiceId));
        });
      });
      this.store$.dispatch(new DeselectShipment(shipment._id));
      this.isShipmentSelected = false;
      this.formState.validForTender = false;
    }
  }

  onServiceSelectionChange(shipment: Shipment, service: ServiceInfo, isSelected: boolean): void {
    if (isSelected) {
      this.store$.dispatch(new SelectService(shipment._id, service.plannedServiceId));
    } else {
      this.store$.dispatch(new DeselectService(shipment._id, service.plannedServiceId));
    }
  }
  isServiceSelected(shipment: Shipment, service: ServiceInfo): boolean {
    const selectedServicesSelector = this.store$.selectSnapshot(WizardState.getSelectedServicesByShipmentId);
    const selectedServices = selectedServicesSelector[shipment._id];
    if (selectedServices) {
      return selectedServices.includes(service.plannedServiceId);
    }
    return false;
  }

  selectOption(shipment: Shipment, option: ServiceOption, isSelected: boolean): void {
    if (isSelected) {
      this.deselectAllOtherShipments(shipment._id);
      this.selectShipmentAndOption(shipment, option);
    } else {
      this.deselectShipmentAndOption(shipment);
    }
  }

  selectService(shipment: Shipment, option: ServiceOption, service: ServiceInfo, isSelected: boolean): void {
    if (isSelected) {
      if (this.selectedShipmentId !== shipment._id) {
        this.deselectAllOtherShipments(shipment._id);
      }
      if (this.selectedOption !== option._id) {
        // If the service is in a different option, deselect the current option and select the new one
        this.deselectShipmentAndOption(shipment);
        this.selectShipmentAndOption(shipment, option);
      }
      this.store$.dispatch(new SelectService(shipment._id, service.plannedServiceId));
    } else {
      this.store$.dispatch(new DeselectService(shipment._id, service.plannedServiceId));

      // Check if all services in the option are deselected
      const allServicesDeselected = option.services.every(s => !this.isServiceSelected(shipment, s));

      if (allServicesDeselected) {
        this.deselectShipmentAndOption(shipment);
      }
    }
  }

  private selectShipmentAndOption(shipment: Shipment, option: ServiceOption): void {
    // Select the current shipment
    this.store$.dispatch(new SelectShipment(shipment._id));
    this.selectedShipmentId = shipment._id;

    // Deselect all options and services for this shipment
    shipment.serviceOptions.forEach(otherOption => {
      otherOption.services.forEach(service => {
        this.store$.dispatch(new DeselectService(shipment._id, service.plannedServiceId));
      });
    });

    // Select all services in the chosen option
    option.services.forEach(service => {
      this.store$.dispatch(new SelectService(shipment._id, service.plannedServiceId));
    });

    // Update the UI to reflect the changes
    this.selectedOption = option._id;
  }

  private deselectShipmentAndOption(shipment: Shipment): void {
    // Deselect the shipment and all its services
    this.store$.dispatch(new DeselectShipment(shipment._id));
    shipment.serviceOptions.forEach(otherOption => {
      otherOption.services.forEach(service => {
        this.store$.dispatch(new DeselectService(shipment._id, service.plannedServiceId));
      });
    });

    // Clear the selected option and shipment
    this.selectedOption = null;
    this.selectedShipmentId = null;
  }

  private deselectAllOtherShipments(selectedShipmentId: string): void {
    this.shipments$.pipe(take(1)).subscribe(shipments => {
      shipments.forEach(shipment => {
        if (shipment._id !== selectedShipmentId) {
          this.deselectShipmentAndOption(shipment);
        }
      });
    });
  }

  viewOrderData(orders: Order[]): void {
    const data = {
      orders,
    };
    this.dialogHandlerService.openDialog(ViewOrderCardsComponent, data, {
      backdropClass: 'backdropBackground',
      width: '800px',
      height: '100%',
      panelClass: 'custom-dialog-container',
    });
  }
}
