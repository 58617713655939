import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { GET_SERVICE_CONFIGS_QUERY } from '@shared/gql-shared-queries';
import { Apollo } from 'apollo-angular';
import { map, take, tap } from 'rxjs';

interface SelectableServiceConfig {
  serviceName: string;
  _id: string;
  selected?: boolean;
}

@Component({
  selector: 'hmt-unsmartened-assign-services-container',
  templateUrl: './unsmartened-assign-services-container.component.html',
  styleUrl: './unsmartened-assign-services-container.component.scss',
})
export class UnsmartenedAssignServicesContainerComponent extends FieldWrapper implements OnInit {
  serviceConfigs: SelectableServiceConfig[] = [];
  allSelected: boolean = false;

  constructor(
    private apollo: Apollo,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.apollo
      .query({
        query: GET_SERVICE_CONFIGS_QUERY,
      })
      .pipe(
        take(1),
        map(res => res.data['hmtServiceConfigs']),
        tap(data => {
          this.serviceConfigs = data.map(service => ({ ...service, selected: false }));
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  selectAll(event: any): void {
    this.serviceConfigs.forEach(service => (service.selected = event.checked));
    if (this.serviceConfigs.some(service => service.selected)) {
      this.field.options.formState.validForTender = true;
    } else {
      this.field.options.formState.validForTender = false;
    }
  }

  checkService(service: SelectableServiceConfig): void {
    this.allSelected = this.serviceConfigs.every(service => service.selected);
    if (this.serviceConfigs.some(service => service.selected)) {
      this.field.options.formState.validForTender = true;
    } else {
      this.field.options.formState.validForTender = false;
    }
  }
}
