import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { Store } from '@ngxs/store';
import { ORDER_CREATE_UI } from '@shared/constants';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';
import { WorkflowScreen } from 'app/json-schema-forms/store/models/workflow-screen.model';
import { FetchInjectedScreen } from 'app/json-schema-forms/store/workflow.actions';
import { filter, first, Observable, Subject, takeUntil, tap } from 'rxjs';
import { OrderCreateModel } from '../models/order.create.model';
import { Order } from '../models/order.model';
import { CreateOrder } from '../store/wizard.actions';
import { WizardService } from './wizard.service';

@Injectable({
  providedIn: 'root',
})
export class ViewOrderService {
  componentDialogRef: MatDialogRef<any>;
  constructor(
    public jsonSchemaService: JsonSchemaService,
    public dialogHandlerService: DialogHandlerService,
    public formlyJsonschema: FormlyJsonschema,
    private store$: Store,
    private wizardService: WizardService
  ) {}

  openOrderView(
    order?: Order,
    injectedScreen$?: Observable<WorkflowScreen>,
    dialogFields?: FormlyFieldConfig[],
    form?: FormGroup,
    options?: FormlyFormOptions,
    dialogRef?: MatDialogRef<any>,
    $destroy: Subject<void> = new Subject<void>(),
    viewOnly: boolean = false
  ): void {
    this.store$.dispatch(new FetchInjectedScreen(ORDER_CREATE_UI));
    injectedScreen$
      .pipe(
        filter(screen => !!screen),
        first(),
        tap(screen => {
          if (screen) {
            let model = { order: order || {} };
            if (order) {
              const extractedKeys = this.jsonSchemaService.generateDataModel(screen.schema.properties);
              const generatedModel = this.jsonSchemaService.populateTemplate(extractedKeys.order, order);
              model = { order: generatedModel };
            }

            if (dialogRef) {
              this.componentDialogRef = dialogRef;
            }

            dialogFields = this.viewOrderFormGenerator(screen.schema, viewOnly, order);
            const buyerField = this.jsonSchemaService.findFormlyFieldConfig(dialogFields[0].fieldGroup, 'buyerId');
            const cargoPickupField = this.jsonSchemaService.findFormlyFieldConfig(
              dialogFields[0].fieldGroup,
              'cargoPickupLocation'
            );
            const sellerField = this.jsonSchemaService.findFormlyFieldConfig(dialogFields[0].fieldGroup, 'sellerId');
            const cargoDropOffField = this.jsonSchemaService.findFormlyFieldConfig(
              dialogFields[0].fieldGroup,
              'cargoDropOffLocation'
            );
            const countryOfOriginField = this.jsonSchemaService.findFormlyFieldConfig(
              dialogFields[0].fieldGroup,
              'countryOfOrigin'
            );
            const countryOfDestinationField = this.jsonSchemaService.findFormlyFieldConfig(
              dialogFields[0].fieldGroup,
              'countryOfDestination'
            );
            sellerField.hooks = {
              onInit: field => {
                field.formControl.valueChanges
                  .pipe(
                    takeUntil($destroy),
                    tap(searchVal => {
                      if (searchVal) {
                        const isSeller = field.formControl?.value?.value === order?.parties?.sellerId;
                        cargoPickupField.props['gqlQuery'].variables.orgId = searchVal?.value || '';
                        cargoPickupField.formControl.setValue(
                          isSeller ? order?.shipmentDetails?.cargoPickupLocation : ''
                        );
                        cargoPickupField.formControl.markAsDirty();
                        cargoPickupField.formControl.markAsTouched();
                      }
                    })
                  )
                  .subscribe();
              },
            };

            buyerField.hooks = {
              onInit: field => {
                field.formControl.valueChanges
                  .pipe(
                    takeUntil($destroy),
                    tap(searchVal => {
                      if (searchVal) {
                        const isBuyer = field.formControl?.value?.value === order?.parties?.buyerId;
                        cargoDropOffField.props['gqlQuery'].variables.orgId = searchVal?.value || '';
                        cargoDropOffField.formControl.setValue(
                          isBuyer ? order?.shipmentDetails?.cargoDropOffLocation : ''
                        );
                        cargoDropOffField.formControl.markAsDirty();
                        cargoDropOffField.formControl.markAsTouched();
                      }
                    })
                  )
                  .subscribe();
              },
            };

            cargoPickupField.hooks = {
              onInit: field => {
                field.formControl.valueChanges
                  .pipe(
                    takeUntil($destroy),
                    tap(searchVal => {
                      if (searchVal) {
                        if (searchVal?.countryCode) {
                          this.wizardService.getCountriesByShortCodes([searchVal.countryCode]).subscribe(countries => {
                            const country = countries[0];
                            if (country) {
                              countryOfOriginField.formControl.setValue({
                                label: country.countryName,
                                value: country._id,
                              });
                            }
                          });
                        } else if (searchVal?.address?.country) {
                          this.wizardService.getCountriesByNames([searchVal.address.country]).subscribe(countries => {
                            const country = countries[0];
                            if (country) {
                              countryOfOriginField.formControl.setValue({
                                label: country.countryName,
                                value: country._id,
                              });
                            }
                          });
                        } else {
                          countryOfOriginField.formControl.setValue({
                            label: '',
                            value: '',
                          });
                        }

                        const sameLocation =
                          field.formControl?.value?.value === order?.shipmentDetails?.cargoPickupLocation;
                        countryOfOriginField.formControl.setValue(
                          sameLocation ? order?.shipmentDetails?.countryOfOrigin : ''
                        );
                        countryOfOriginField.formControl.markAsDirty();
                        countryOfOriginField.formControl.markAsTouched();
                      } else {
                        countryOfOriginField.formControl.setValue('');
                      }
                    })
                  )
                  .subscribe();
              },
            };

            cargoDropOffField.hooks = {
              onInit: field => {
                field.formControl.valueChanges
                  .pipe(
                    takeUntil($destroy),
                    tap(searchVal => {
                      if (searchVal) {
                        countryOfDestinationField.formControl.markAsDirty();
                        countryOfDestinationField.formControl.markAsTouched();
                        if (searchVal?.address?.country) {
                          this.wizardService.getCountriesByNames([searchVal.address.country]).subscribe(countries => {
                            const country = countries[0];
                            if (country) {
                              countryOfDestinationField.formControl.setValue({
                                label: country.countryName,
                                value: country._id,
                              });
                            }
                          });
                        } else if (searchVal?.countryCode) {
                          this.wizardService.getCountriesByShortCodes([searchVal.countryCode]).subscribe(countries => {
                            const country = countries[0];
                            if (country) {
                              countryOfDestinationField.formControl.setValue({
                                label: country.countryName,
                                value: country._id,
                              });
                            }
                          });
                        } else {
                          countryOfDestinationField.formControl.setValue({
                            label: '',
                            value: '',
                          });
                        }

                        const sameLocation =
                          field.formControl?.value?.value === order?.shipmentDetails?.cargoDropOffLocation;
                        countryOfDestinationField.formControl.setValue(
                          sameLocation ? order?.shipmentDetails?.countryOfDestination : ''
                        );
                      } else {
                        countryOfDestinationField.formControl.setValue('');
                      }
                    })
                  )
                  .subscribe();
              },
            };

            this.componentDialogRef = this.dialogHandlerService.openSchemaDialog(dialogFields, model, form, options, {
              backdropClass: 'backdropBackground',
              width: '800px',
              height: '100%',
              panelClass: 'custom-dialog-container',
            });
            this.componentDialogRef.componentInstance.submitModel
              .pipe(
                takeUntil($destroy),
                tap(result => {
                  const buttonObject = this.jsonSchemaService.findObjectByKey(screen.schema, 'confirm');
                  const variableObject = this.jsonSchemaService.findObjectByKey(
                    buttonObject['confirm'],
                    'variables'
                  )?.variables;
                  const orderCreateModel = this.jsonSchemaService.nestedVariableExtractor(
                    variableObject,
                    result as object
                  ) as OrderCreateModel;

                  if (order) {
                    orderCreateModel._id = order._id;
                  }

                  if (orderCreateModel) {
                    this.store$.dispatch(new CreateOrder(orderCreateModel));
                  }
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();
  }

  viewOrderFormGenerator(schema: any, viewOnly: boolean, order?: Order) {
    return [
      this.formlyJsonschema.toFieldConfig(schema, {
        map: field => {
          switch (field.key) {
            case 'footer':
              field.fieldGroup.find(f => f.key === 'cancel').props['onClick'] = () => this.componentDialogRef.close();
              break;
            case 'cancel':
              if (viewOnly) {
                field.props['onClick'] = () => this.componentDialogRef.close();
                field.props['disabled'] = false;
              }
              break;
            case 'order':
              if (order?.orderRefNumber) {
                field.props['label'] = order.orderRefNumber;
              }
              break;
            case 'createOrders':
              if (field.props['openDialog'] && field.props['gqlQuery']) {
                field.props['onClick'] = () => this.openOrderView(field.props['gqlQuery']);
              }
              break;
            default:
              if (viewOnly && field.key !== 'footer') {
                field.props['disabled'] = true;
              }
          }
          return field;
        },
      }),
    ];
  }
}
