import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JobUpdateNotification } from '@core/models/job-updates-notification.model';
import { GraphqlClientService } from '@core/services/graphql-client.service';
import { ClearJobUpdates } from '@core/store/job-updates/job-updates.actions';
import { JobUpdatesState } from '@core/store/job-updates/job-updates.state';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { SHIPMENT_MODES_QUERY } from '@shared/gql-shared-queries';
import { Pageable } from '@shared/models/pageable.model';
import { FetchServiceView } from 'app/json-schema-forms/store/workflow.actions';
import { WorkflowState } from 'app/json-schema-forms/store/workflow.state';
import { filter, Observable, Subject, takeUntil, tap } from 'rxjs';
import { JobFile } from '../../models/job-file.model';
import { Order } from '../../models/order.model';
import { CreateShipment, FetchAllShipments, FetchServiceCard } from '../../store/wizard.actions';
import { WizardState } from '../../store/wizard.state';
import { OrderCardComponent } from '../order-card/order-card.component';

@Component({
  selector: 'hmt-shipments',
  templateUrl: './shipments.component.html',
  styleUrl: './shipments.component.scss',
})
export class ShipmentsComponent extends FieldType implements OnInit, OnDestroy {
  private readonly store$ = inject(Store);

  currentScreen$ = this.store$.select(WorkflowState.getCurrentScreen);
  injectedScreen$ = this.store$.select(WorkflowState.getInjectedScreen);
  shipments$ = this.store$.select(WizardState.getShipments);
  jobFile$ = this.store$.select(WizardState.getJobFile);
  assignedServices$ = this.store$.select(WizardState.getAssignedServices);
  shipmentServiceOptions$ = this.store$.select(WizardState.getShipmentServiceOptions);
  getDefaultServiceConfigs$ = this.store$.select(WorkflowState.getDefaultServiceConfigs);

  private $destroy: Subject<void> = new Subject<void>();
  private readonly formlyJsonschema = inject(FormlyJsonschema);
  public translate = inject(TranslateService);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly graphqlClient = inject(GraphqlClientService);
  @ViewChild('hmt-order-card') orderCard: OrderCardComponent;

  jobFile: JobFile;
  fields: FormlyFieldConfig[] = [];
  dialogFields: FormlyFieldConfig[] = [];
  dialogRef: MatDialogRef<any>;
  shipmentMethods: { key: string; value: string; transhipmentAllowed: boolean }[] = [];
  parentJobRefId: string;
  latestJobUpdates$: Observable<JobUpdateNotification> = this.store$.select(JobUpdatesState.getLatestJobUpdates);
  categorizedOrders$: Observable<(shipmentId: string) => Pageable<Order>[]> = this.store$.select(
    WizardState.getCategorizedOrders
  );

  ngOnInit(): void {
    this.listenToActions();
    this.createShipmentMethods();

    this.jobFile$
      .pipe(
        takeUntil(this.$destroy),
        tap((jobFile: JobFile) => (this.jobFile = jobFile))
      )
      .subscribe();
  }

  createShipment() {
    this.store$.dispatch(new CreateShipment({ shipmentRefNumber: '', orders: [] }));
  }

  formsGenerator(schema, fields) {
    return [
      this.formlyJsonschema.toFieldConfig(schema, {
        map: field => {
          if (field.key === 'createShipment' && field.props['openDialog'] && field.props['gqlQuery']) {
            // TODO: open the create shipment dialog
            field.props['onClick'] = () => {
              this.createShipment();
            };
          }
          return field;
        },
      }),
    ];
  }

  listenToActions(): void {
    this.jobFile$
      .pipe(
        takeUntil(this.$destroy),
        tap((jobFile: JobFile) => {
          if (jobFile) {
            this.parentJobRefId = jobFile?.jobRefId;
            this.store$.dispatch(new FetchAllShipments(jobFile?.jobRefId));
          }
        })
      )
      .subscribe();

    this.latestJobUpdates$
      .pipe(
        takeUntil(this.$destroy),
        filter((jobUpdate: JobUpdateNotification) => {
          return jobUpdate && jobUpdate.entityType === 'SHIPMENT';
        }),
        tap((jobUpdate: JobUpdateNotification) => {
          // TODO: Fetch only the updated order
          this.store$.dispatch(new FetchAllShipments(jobUpdate?.jobRefId));
        })
      )
      .subscribe();
  }

  setSelectedService(event: {
    shipmentId: string;
    serviceOptionId: string;
    serviceId: string;
    screenId: string;
  }): void {
    if (event) {
      this.store$.dispatch(new ClearJobUpdates());
      this.store$.dispatch(new FetchServiceView(event?.screenId));
      this.store$.dispatch(new FetchServiceCard(event?.shipmentId, event?.serviceOptionId, event?.serviceId));
      this.router.navigate([`service-view`, event?.screenId], {
        relativeTo: this.activatedRoute,
        queryParams: {
          ...this.activatedRoute.snapshot.queryParams,
          shipmentId: event?.shipmentId,
          serviceOptionId: event?.serviceOptionId,
          serviceId: event?.serviceId,
        },
      });
    }
  }

  createShipmentMethods() {
    this.graphqlClient
      .query<{ getShipmentModes: { key: string; value: string }[] }, undefined>(SHIPMENT_MODES_QUERY)
      .pipe(
        tap((shipmentModes: { getShipmentModes: { key: string; value: string; transhipmentAllowed: boolean }[] }) => {
          this.shipmentMethods = shipmentModes.getShipmentModes;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
