import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';
import { Select, Store } from '@ngxs/store';
import { COUNTRY_FLAG_CDN_URL } from '@shared/constants';
import { PreSignedUrl } from '@shared/models/pre-signed-url.model';
import { FileHandlerService } from '@shared/services/file-handler.service';
import { WorkflowScreen } from 'app/json-schema-forms/store/models/workflow-screen.model';
import { WorkflowState } from 'app/json-schema-forms/store/workflow.state';
import { catchError, filter, map, Observable, of, Subject, take } from 'rxjs';
import { Order } from '../../models/order.model';
import { SelectableOrder } from '../../models/selecrtable-order.model';
import { Shipment } from '../../models/shipment.model';
import { ViewOrderService } from '../../services/view-order.service';
import { SelectOrder } from '../../store/wizard.actions';

@Component({
  selector: 'hmt-order-card',
  templateUrl: './order-card.component.html',
  styleUrl: './order-card.component.scss',
})
export class OrderCardComponent extends FieldWrapper implements OnInit, OnDestroy {
  @Input() order: SelectableOrder;
  @Input() parentJobRefId: string;

  @Input() shipment: Shipment;
  @Input() viewOnly: boolean = false;
  @Input() hideCheckBox: boolean = false;
  @Input() deletable: boolean = false;
  @Input() disableDelete: boolean = false;
  @Output() editOrderAction = new EventEmitter<Order>();
  @Output() deleteOrderAction = new EventEmitter<Order>();
  @Output() viewOrderAction = new EventEmitter<Order>();
  @Output() selectOrderAction = new EventEmitter<{ checked: boolean; order: Order }>();

  @Select(WorkflowState.getInjectedScreen) injectedScreen$: Observable<WorkflowScreen>;

  statusChipColor: string;
  cargoReadyDateFormatted: string;
  cargoInHouseDateFormatted: string;
  shipperLogo: Observable<{ url: string | null }>;
  consigneeLogo: Observable<{ url: string | null }>;

  dialogFields: FormlyFieldConfig[] = [];
  dialogRef: MatDialogRef<unknown>;
  private onDestroy$ = new Subject<void>();

  constructor(
    private viewOrderService: ViewOrderService,
    private store$: Store,
    private fileHandlerService: FileHandlerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.cargoReadyDateFormatted = this.formatDate(this.order?.keyDates['plannedCargoReadyDate']);
    this.cargoInHouseDateFormatted = this.formatDate(this.order?.keyDates['inHouseReadyDate']);
    this.fetchLogos();
  }

  fetchLogos() {
    if (this.order?.parties?.seller?.logo) {
      this.shipperLogo = this.fileHandlerService.fetchFile(this.order?.parties.seller?.logo).pipe(
        take(1),
        map(logo => (logo ? { url: logo.url } : { url: 'assets/placeholders/image_placeholder.svg' })),
        catchError(error => {
          console.error('Failed to load shipper logo:', error);
          return of({ url: 'assets/placeholders/image_placeholder.svg' });
        })
      );
    }

    if (this.order?.parties?.buyer?.logo) {
      this.consigneeLogo = this.fileHandlerService.fetchFile(this.order?.parties.buyer?.logo).pipe(
        take(1),
        map(logo => (logo ? { url: logo.url } : { url: 'assets/placeholders/image_placeholder.svg' })),
        catchError(error => {
          console.error('Failed to load consignee logo:', error);
          return of({ url: 'assets/placeholders/image_placeholder.svg' });
        })
      );
    }
  }

  // getStatusChipColorClass(status: OrderStatus): string {
  //   return status.toLowerCase();
  // }

  getFlagIconUrl(countryCode: string): string {
    return COUNTRY_FLAG_CDN_URL(countryCode);
  }

  formatDate(isoDateString: Date): string {
    const DOUBLE_DIGIT = 10;
    const date = new Date(isoDateString);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    // Format day and month to ensure they are always two digits
    const dayStr = day < DOUBLE_DIGIT ? `0${day}` : day;
    const montStr = month < DOUBLE_DIGIT ? `0${month}` : month;

    return `${dayStr}/${montStr}/${year}`;
  }

  edit() {
    this.editOrderAction.emit(this.order);
  }

  delete() {
    this.deleteOrderAction.emit(this.order);
  }

  view() {
    // openOdder view is called here because the order card is used in multiple places
    // and the view order service is a shared service
    this.store$.dispatch(new SelectOrder(this.order?._id));
    this.viewOrderService.openOrderView(
      this.order,
      this.injectedScreen$.pipe(filter(screen => screen !== null)),
      this.dialogFields,
      null,
      this.options,
      this.dialogRef,
      this.onDestroy$,
      true
    );
  }

  markAsChecked(checked: boolean): void {
    this.order.selected = checked;
    this.selectOrderAction.emit({ checked: checked, order: this.order });
  }

  fetchFile(fileId: string): Observable<PreSignedUrl> {
    return this.fileHandlerService.fetchFile(fileId).pipe(take(1));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
