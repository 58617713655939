<mat-card class="cargo-card">
  <div class="header">
    <div class="order-ref-container">
      <mat-checkbox
        class="checkbox"
        *ngIf="!hideCheckBox"
        [ngModel]="order?.selected"
        (ngModelChange)="markAsChecked($event)"></mat-checkbox>
      <div class="shipment-info-container">
        <div class="font-bold order-ref-no">Ref No : {{ order?.orderRefNumber }}</div>
      </div>
    </div>
    <div class="shipping-details">
      <span class="incoterm">{{ order?.shipmentDetails?.incoterm }}</span>
      <mat-divider vertical="true" class="fob-divider"></mat-divider>
      <div class="locations">
        <div class="flag-icon">
          <img [src]="getFlagIconUrl(order?.shipmentDetails?.cargoPickupCountryCode)" alt="flag" />
        </div>
        <p>{{ order?.shipmentDetails?.cargoPickupCountryCode }}</p>
        <div>
          <mat-icon color="primary">arrow_forward</mat-icon>
        </div>
        <div class="flag-icon">
          <img [src]="getFlagIconUrl(order?.shipmentDetails?.cargoDropOffCountryCode)" alt="flag" />
        </div>
        <p>{{ order?.shipmentDetails?.cargoDropOffCountryCode }}</p>
      </div>
    </div>
    <div class="actions-container">
      <div class="actions">
        <ng-container *ngIf="!viewOnly; else viewOnlyBtn">
          <button (click)="edit()" class="edit-btn" mat-mini-fab aria-label="Edit">
            <mat-icon>edit</mat-icon>
          </button>
          <button (click)="delete()" class="delete-btn" mat-mini-fab aria-label="Delete">
            <img src="./assets/icons/delete.svg" alt="delete service icon" />
          </button>
        </ng-container>
        <ng-template #viewOnlyBtn>
          <button (click)="view()" class="visibility-btn" mat-mini-fab aria-label="View">
            <mat-icon>visibility</mat-icon>
          </button>
          <button
            (click)="delete()"
            class="delete-btn"
            mat-mini-fab
            aria-label="Delete"
            *ngIf="deletable"
            [disabled]="disableDelete">
            <img src="./assets/icons/delete.svg" alt="delete service icon" />
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <div class="details">
    <div class="dates-info-container">
      <div class="cargo-ready-date">
        <div class="font-bold">Cargo Ready Date</div>
        <div>{{ cargoReadyDateFormatted }}</div>
      </div>
      <div class="cargo-in-house-date">
        <div class="font-bold">Cargo In-House Date</div>
        <div>{{ cargoInHouseDateFormatted }}</div>
      </div>
    </div>
    <mat-divider vertical="true"></mat-divider>
    <div class="parties-info-container">
      <div class="shipper">
        <img *ngIf="(shipperLogo | async)?.url" [src]="(shipperLogo | async)?.url" alt="Shipper" />
        <img
          *ngIf="!(shipperLogo | async)?.url"
          src="assets/placeholders/image_placeholder.svg"
          alt="Default Shipper Logo" />
        <div>
          <div class="font-bold">Shipper</div>
          <div>{{ order?.parties?.seller?.orgName }}</div>
        </div>
      </div>
      <div class="consignee">
        <img *ngIf="(consigneeLogo | async)?.url" [src]="(consigneeLogo | async)?.url" alt="Consignee" />
        <img
          *ngIf="!(consigneeLogo | async)?.url"
          src="assets/placeholders/image_placeholder.svg"
          alt="Default Consignee Logo" />
        <div>
          <div class="font-bold">Consignee</div>
          <div>{{ order?.parties?.buyer?.orgName }}</div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
