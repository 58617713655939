<form [formGroup]="formGroup" class="shipment-method-selector">
  <div class="title">Shipment Method</div>
  <div class="shipment-type">
    <mat-radio-group
      [disabled]="viewOnly"
      aria-label="Select an option"
      [value]="selectedLoadType"
      (change)="changeLoadType($event)">
      <mat-radio-button *ngFor="let loadType of loadTypes" [value]="loadType.type">{{
        loadType.type
      }}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div formArrayName="lcl" *ngIf="selectedLoadType === 'LCL'">
    <div *ngFor="let item of lclArray?.controls; let i = index" [formGroupName]="i">
      <div class="lcl">
        <mat-form-field>
          <mat-label>Volume Weight</mat-label>
          <input matInput type="number" formControlName="volumeWeight" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>No of Truck Loads</mat-label>
          <input matInput type="number" formControlName="loadCount" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div formArrayName="fcl" *ngIf="selectedLoadType === 'FCL'">
    <div *ngFor="let item of fclArray?.controls; let i = index" [formGroupName]="i">
      <div class="row-1">
        <div class="fcl">
          <mat-form-field>
            <mat-label>Container Type</mat-label>
            <mat-select formControlName="containerType" (selectionChange)="selectContainerType($event)">
              <mat-option *ngFor="let containerType of containerTypes" [value]="containerType.id">{{
                containerType.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>No of Containers</mat-label>
            <input matInput type="number" formControlName="containerCount" />
          </mat-form-field>
          <button
            mat-mini-fab
            class="delete-btn"
            [ngClass]="{ disabled: viewOnly }"
            [disabled]="viewOnly"
            (click)="deleteItem(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="footer" *ngIf="selectedLoadType === 'FCL'">
    <button mat-mini-fab class="add-btn" [ngClass]="{ disabled: viewOnly }" [disabled]="viewOnly" (click)="addFCL()">
      <mat-icon>add</mat-icon>
    </button>
    <span [ngClass]="{ disabled: viewOnly }">Add Container Type</span>
    <div class="horizontal-line"></div>
  </div>
</form>
