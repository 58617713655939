import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ServiceCard } from '../../models/service-card/service-card.model';
import { ShipmentMethodDetail } from '../../models/shipment-method-details';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-service-shipment-method-selector',
  templateUrl: './service-shipment-method-selector.component.html',
  styleUrl: './service-shipment-method-selector.component.scss',
})
export class ServiceShipmentMethodSelectorComponent extends FieldType implements OnInit, OnDestroy {
  @Select(WizardState.getServiceCard) selectedServiceCard$: Observable<ServiceCard>;
  initialLoadType: string = 'FCL';
  initialLoadDetails: ShipmentMethodDetail[] = [];
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.selectedServiceCard$.pipe(takeUntil(this.destroy$)).subscribe(serviceCard => {
      if (serviceCard) {
        this.initialLoadType = serviceCard?.loadDetails[0]?.containerType ? 'FCL' : 'LCL';
        this.initialLoadDetails = serviceCard?.loadDetails || [];
      }
    });
  }

  onLoadDetailsChange(shipmentData: { loadDetails: ShipmentMethodDetail[]; shipmentMethod: string }): void {
    // Check if the model exists. If it does, we're likely in edit mode.
    // If not, we're in create mode and need to use the form controls directly.
    if (this.model) {
      this.model['loadDetails'] = shipmentData?.loadDetails;
      this.model['shipmentMethod'] = shipmentData?.shipmentMethod;
    } else {
      this.field.form.controls['loadDetails'].patchValue(shipmentData?.loadDetails);
      this.field.form.controls['shipmentMethod'].patchValue(shipmentData?.shipmentMethod);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
