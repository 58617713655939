<mat-card class="service-selection-card">
  <mat-card-header>
    <mat-card-title>What Services Do You Need ?</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    @if (!shipmentServiceOptions || shipmentServiceOptions.length === 0) {
      <ng-container *ngTemplateOutlet="optionContent; context: { option: null, index: 0 }"></ng-container>
    } @else {
      <div [formGroup]="formGroup">
        <ng-container formArrayName="serviceOptions">
          @for (option of serviceOptionsFormArray.controls; track option; let i = $index) {
            <ng-container [formGroupName]="i">
              <ng-container *ngTemplateOutlet="optionContent; context: { option: option, index: i }"></ng-container>
            </ng-container>
          }
        </ng-container>
      </div>
    }
  </mat-card-content>
  <div class="options-container">
    <button
      class="hmt-fill-btn"
      mat-flat-button
      [disabled]="!shipmentServiceOptions?.length || createOptionDisabled"
      (click)="createServiceOption()">
      Create an Option<mat-icon>add</mat-icon>
    </button>
  </div>
</mat-card>

<ng-template #optionContent let-option="option" let-index="index">
  <div class="content-container">
    <div class="option-row">
      <h2>Option {{ index + 1 }}</h2>
      <button
        class="delete-btn"
        mat-mini-fab
        aria-label="Delete"
        [disabled]="!option"
        (click)="removeServiceOption(index)">
        <img src="./assets/icons/delete.svg" alt="delete service icon" />
      </button>
    </div>
    <div class="selection-group">
      @if (option) {
        <div class="selection-group" [formGroup]="option">
          <mat-form-field appearance="outline">
            <mat-label>Mode</mat-label>
            <mat-select formControlName="shipmentMode" (selectionChange)="onShipmentModeChange(index)">
              <mat-option *ngFor="let shipmentMethod of shipmentMethods" [value]="shipmentMethod?.key">
                {{ shipmentMethod?.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      } @else {
        <div class="selection-group" [formGroup]="emptyFormGroup">
          <mat-form-field appearance="outline">
            <mat-label>Mode</mat-label>
            <mat-select formControlName="shipmentMode" (selectionChange)="onEmptyShipmentModeChange($event)">
              <mat-option *ngFor="let shipmentMethod of shipmentMethods" [value]="shipmentMethod?.key">
                {{ shipmentMethod?.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      }
    </div>
    <div class="dynamic-service-container">
      <div class="service-selection">
        <h3>Select Services</h3>
        <p>Select the Services You Require for this Shipment?</p>
        <button
          (click)="openDialog(index)"
          mat-raised-button
          color="primary"
          [disabled]="
            !orders ||
            !orders.length ||
            (option ? !option.get('shipmentMode').value : !emptyFormGroup.get('shipmentMode').value) ||
            option?.get('services')?.value?.length > 0
          ">
          <mat-icon>add</mat-icon>
          Select
        </button>
      </div>

      @if (option?.get('services')?.value?.length) {
        <div class="service-flow-container scroll-container" #serviceFlowScrollContainer>
          @for (service of option.get('services').value; track service; let i = $index) {
            <ng-container>
              @if (i === 0) {
                <div class="arrow-connector">
                  <mat-divider vertical="true"></mat-divider>
                  <div
                    [ngClass]="{ 'disabled-add-service': addServiceCardDisabled(option, i) }"
                    (click)="addServiceCard(index, i, service)"
                    (keydown.enter)="addServiceCard(index, i, service)"
                    tabindex="0">
                    <img
                      class="add-service-icon"
                      [ngClass]="{ 'disabled-add-service': addServiceCardDisabled(option, i) }"
                      src="./assets/icons/plus.svg"
                      alt="plus icon to add a service" />
                  </div>
                </div>
              }
              <div class="service-card" tabindex="0">
                <div
                  class="delete-row delete-pointer"
                  [ngClass]="{
                    'disabled-delete': deleteServiceCardDisabled(option),
                    'disabled-delete-pointer': deleteServiceCardDisabled(option)
                  }"
                  (click)="deleteServiceCard(service)"
                  (keydown.enter)="deleteServiceCard(service)"
                  tabindex="0">
                  <img
                    [ngClass]="{
                      'disabled-delete': deleteServiceCardDisabled(option),
                      'disabled-delete-pointer': deleteServiceCardDisabled(option)
                    }"
                    [src]="
                      deleteServiceCardDisabled(option)
                        ? './assets/icons/disabled_delete.svg'
                        : './assets/icons/delete.svg'
                    "
                    alt="delete service icon" />
                </div>
                <div class="service-icon-row">
                  <img [src]="'./assets/icons/service-icons/' + service.serviceId + '.svg'" alt="truck icon" />
                </div>
                <mat-divider></mat-divider>
                <div
                  class="service-name-row"
                  tabindex="0"
                  (click)="openServiceView(index, service)"
                  (keydown.enter)="openServiceView(index, service)">
                  <div class="service-name">{{ service.serviceId }}</div>
                  <div class="nav-icon">
                    <mat-icon>chevron_right</mat-icon>
                  </div>
                </div>
              </div>
              <div class="arrow-connector">
                <mat-divider vertical="true"></mat-divider>
                <div
                  [ngClass]="{ 'disabled-add-service': addServiceCardDisabled(option, i + 1) }"
                  (click)="addServiceCard(index, i + 1, service)"
                  (keydown.enter)="addServiceCard(index, i + 1, service)"
                  tabindex="0">
                  <img
                    class="add-service-icon"
                    [ngClass]="{ 'disabled-add-service': addServiceCardDisabled(option, i + 1) }"
                    src="./assets/icons/plus.svg"
                    alt="plus icon to add a service" />
                </div>
              </div>
            </ng-container>
          }
        </div>
      }

      <div class="service-options-container">
        <button
          class="hmt-stroked-btn"
          mat-stroked-button
          (click)="duplicateServiceOption(option)"
          [disabled]="!shipmentServiceOptions?.length || createOptionDisabled">
          Duplicate & Edit<mat-icon>content_copy</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
